import {
  faArrowLeft,
  faBars,
  faCalendarDays,
  faCalendarXmark,
  faClipboard,
  faFontAwesome,
  faGear,
  faLocationDot,
  faMoneyBill,
  faNotesMedical,
  faQuestion,
  faUserPen,
  faUsers
  // faVolumeUp
} from '@fortawesome/free-solid-svg-icons'
import React, { Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import SidebarItem from './component/SidebarItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fileSrc } from '../../component/FileLoader'
import { useQuery } from '@tanstack/react-query'
import { settings_api } from '../../service/settings'
import Notification from './component/Notification'
import UserProfile from './component/UserProfile'
import { useAuth } from '../../context/AuthContext'
import { ADMIN } from '../../consts'
import { Alerts } from './component/Alerts'
import { get_request_api } from '../../service/misc'
import { GuidedSetup } from './component/GuidedSetup'

export default function WebLayout() {
  const { currentUser } = useAuth()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { data: settings, refetch } = useQuery({
    queryKey: ['settings'],
    queryFn: settings_api
  })
  const { data: alerts } = useQuery({
    queryKey: ['alerts'],
    queryFn: get_request_api,
    enabled: currentUser?.permissions?.find((p: any) => p.name === 'settings')
      ?.read
  })
  const [showMobileSideBar, setShowMobileSideBar] = useState(false)

  let styleHearder: any = {
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    fontFamily: 'Inter'
  }
  return (
    <div className='h-screen bg-[#F1F4FB]'>
      <header
        className={`no-print sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-30
				 w-full bg-white border-b text-sm py-2.5 sm:py-4  transition-all duration-300 
				 ${isMenuOpen && 'lg:ps-24 '}
				 ${!isMenuOpen && 'lg:ps-64 '}`}
      >
        <nav
          className='flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8'
          aria-label='Global'
        >
          <div className='me-5 lg:me-0 lg:hidden'>
            <div className='flex-none text-xl font-semibold flex'>
              <button
                onClick={() => setShowMobileSideBar(!showMobileSideBar)}
                type='button'
                className='pr-2 text-gray-500 hover:text-gray-600'
              >
                <span className='sr-only'>Toggle Navigation</span>
                <svg
                  className='flex-shrink-0 size-6'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <line x1='3' x2='21' y1='6' y2='6' />
                  <line x1='3' x2='21' y1='12' y2='12' />
                  <line x1='3' x2='21' y1='18' y2='18' />
                </svg>
              </button>
              <img
                className='sm:h-8 sm:w-8 h-14 object-contain'
                src={
                  settings?.business_logo ?
                    fileSrc({
                      id: settings?.business_logo,
                      provider: 'cloudinary',
                      format: ''
                    })
                  : 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
                }
                alt=''
              />
            </div>
          </div>

          <div className='w-full flex items-center  justify-end ms-auto sm:justify-between sm:gap-x-3 sm:order-3'>
            <div className='flex item-center '>
              <button
                className='mr-3 invisible lg:visible'
                onClick={() => setMenuOpen(!isMenuOpen)}
              >
                {!isMenuOpen && <FontAwesomeIcon icon={faBars} />}
                {isMenuOpen && <FontAwesomeIcon icon={faArrowLeft} />}
              </button>
              <p
                style={styleHearder}
                className={`px-2 py-auto text-sm uppercase ${settings?.business_logo ? 'invisible' : ''} lg:visible`}
              >
                {settings?.business_name}
              </p>
            </div>
            <div className='flex flex-row items-center justify-end gap-2'>
              <Notification />
              <UserProfile />
            </div>
          </div>
        </nav>
      </header>

      {showMobileSideBar && (
        <button
          onClick={() => setShowMobileSideBar(!showMobileSideBar)}
          className='text-gray-400 hover:text-white absolute bg-black z-30 opacity-5 h-screen w-full top-0 m-1 right-0'
        >
          {/* &times; */}
        </button>
      )}

      <div
        id='application-sidebar'
        className={`no-print 
					 transition-all duration-300
					 transform  fixed lg:top-0 lg:start-0 lg:bottom-0 ${!isMenuOpen ? 'lg:w-64' : 'lg:w-28'} 
					 bg-white border-e border-gray-200 fixed z-40 
					 pt-3 pb-10 ${!isMenuOpen ? 'overflow-hidden ' : ''} 
					 block lg:end-auto lg:bottom-0 lg:translate-x-0 ${showMobileSideBar ? 'translate-x-0' : '-translate-x-full'}
					 `}
      >
        <div className='px-6'>
          <span
            className='flex-none text-xl flex font-semibold '
            aria-label='Brand'
          >
            <img
              className='h-14 object-contain m-auto'
              src={
                settings?.business_logo ?
                  fileSrc({
                    id: settings?.business_logo,
                    provider: 'cloudinary',
                    format: ''
                  })
                : 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
              }
              alt=''
            />
          </span>
        </div>

        <nav
          className='hs-accordion-group pt-2 pl-5 w-full  flex flex-col flex-wrap'
          data-hs-accordion-always-open
        >
          <ul className='space-y-1.5 '>
            {/* <SidebarItem
								isMenuOpen={isMenuOpen}
								link={'dashboard'}
								id={'dashboard'}
								title={'Dashboard'}
								icon={faHouse}
							/> */}

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'schedules'}
              id={'schedules'}
              title={'Schedules'}
              icon={faCalendarDays}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'locations'}
              id={'locations'}
              title={'Clients'}
              icon={faLocationDot}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'services'}
              id={'services'}
              title={'Services'}
              icon={faNotesMedical}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'finances'}
              id={'finances'}
              title={'Finance'}
              icon={faMoneyBill}
              dropdownList={[
                { link: 'invoices', title: 'Invoices', id: 'invoices' },
                { link: 'payrolls', title: 'Payrolls', id: 'payrolls' },
                { link: 'expenses', title: 'Expenses', id: 'expenses' }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />

            {/* <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'communications'}
              id={'communications'}
              title={'Chat'}
              icon={faVolumeUp}
            /> */}
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'users'}
              id={'users'}
              title={'User Management'}
              icon={faUsers}
              dropdownList={[
                {
                  link: '',
                  title: 'Team Members',
                  id: 'users'
                },
                {
                  link: 'roles',
                  title: 'Roles',
                  id: 'roles'
                }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'timeoff'}
              id={'timeoff'}
              title={'Time off'}
              icon={faCalendarXmark}
              dropdownList={[
                {
                  link: 'timeoff-requests',
                  title: 'Time Off Requests',
                  id: 'timeoff-requests'
                },
                {
                  link: 'timeoff-categories',
                  title: 'Time Off Category',
                  id: 'timeoff-categories'
                }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'report'}
              id={'report'}
              title={'Report'}
              icon={faClipboard}
              dropdownList={[
                {
                  link: 'timesheets',
                  title: 'Timesheet',
                  id: 'timesheets'
                },
                {
                  link: 'payroll',
                  title: 'Payroll',
                  id: 'payroll'
                },
                {
                  link: 'invoice',
                  title: 'Invoice',
                  id: 'invoice'
                }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'settings'}
              id={'settings'}
              title={'Settings'}
              icon={faGear}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'audit-logs'}
              id={'audit-logs'}
              title={'Audit'}
              icon={faFontAwesome}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'profile'}
              id={'profile'}
              title={'Profile'}
              icon={faUserPen}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'support'}
              id={'support'}
              title={'Support'}
              icon={faQuestion}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'faq'}
              id={'faq'}
              title={'FAQ'}
              icon={faQuestion}
              onClick={() => setShowMobileSideBar(false)}
            />
          </ul>
        </nav>
      </div>
      <div
        id='c-close'
        className={`w-full h-max pt-4 sm:pt-7 px-4 transition-all duration-300 md:px-8 
				${!isMenuOpen ? 'lg:ps-72' : 'lg:ps-32'} 
				bg-[#F1F4FB]`}
      >
        <Suspense>
          {alerts?.length ?
            <Alerts alerts={alerts} />
          : null}
          {ADMIN?.includes(currentUser.role?.name) ?
            <GuidedSetup settings={settings} refetch={refetch} />
          : null}
          <Outlet />
        </Suspense>
        <br />
        <br /> <br />
      </div>
    </div>
  )
}
