// api.js
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const login_api = async (
  data: AuthModel
): Promise<{
  token: string
  token_type: string
  refresh_token: string
  businesses?: BusinessModel[]
}> =>
  await axios.post(`${process.env.REACT_APP_BASE_URL_V2}/auth/login`, data, {
    headers: {
      Authorization: `Basic ${btoa(`${data.email}:${data.password}`)}`
    }
  })
export const register_api = async (
  data: RegisterModel
): Promise<{
  token: string
  token_type: string
  refresh_token: string
  businesses: BusinessModel[]
}> => {
  return await axios.post(`${BASE_URL}/business`, data)
}
export const login_with_token_api = async ({
  business_id,
  token
}: {
  business_id: string
  token: string
}): Promise<{ token: string; token_type: string; refresh_token: string }> => {
  return await axios.post(
    `${BASE_URL}/auth/login_to_business`,
    {
      business_id
    },
    { headers: { Authorization: `Basic ${token}` } }
  )
}
export const refresh_token_api = async (data: {
  refresh_token: string
}): Promise<{ token: string; token_type: string; refresh_token: string }> => {
  return await axios.post(`${BASE_URL}/auth/refresh_token`, data)
}

export const forget_password_api = async (data: any) => {
  return await axios.post(`${BASE_URL}/auth/reset_password_request`, data)
}

export const otp_api = async (data: any): Promise<any> => {
  return await axios.post(
    `${BASE_URL}/auth/verify_reset_token`,
    data.queryKey[1]
  )
}
export const update_email_api = async (data: any): Promise<AuthModel> => {
  return await axios.put(`${BASE_URL}/auth/update_email`, data)
}
export const change_password_api = async (data: any) => {
  return await axios.post(`${BASE_URL}/auth/change_password`, data)
}

export const update_password_api = async (data: any): Promise<AuthModel> => {
  return await axios.put(`${BASE_URL}/auth/update_password`, data)
}
type AuthModel = {
  email: string
  password: string
  business_id?: string
}
type RegisterModel = {
  company: {
    business_name: string
    business_email: string
    address: string
  }
  contact: {
    email: string
    firstname: string
    lastname: string
  }
}
export type BusinessModel = {
  id: string
  name: string
}
