import React from 'react'
import { Button2, Button2Props } from './Button'

const Modal = ({
  showModal,
  setShowModal,
  title,
  children
}: {
  showModal: any
  setShowModal: any
  title: any
  children: any
  size: 'md' | 'lg'
}) => {
  const closeModal = () => {
    setShowModal(false)
  }

  return (
    showModal && (
      <div className='fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-30 before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto'>
        <div className='fixed inset-0 transition-opacity' onClick={closeModal}>
          <div className='absolute inset-0 bg-gray-500/75 opacity-5'></div>
        </div>
        <div
          className='relative w-full max-w-lg transform bg-white rounded-lg shadow-lg text-left transition-all sm:max-w-2xl sm:w-full'
          // className='relative w-full max-w-lg bg-white shadow-lg rounded-lg p-4'
        >
          <div className='mb-4 mt-6 flex items-start justify-between'>
            <div
              className={`absolute top-0 left-0 w-[80%] bg-primary text-white text-center py-2 rounded-tr-lg`}
            >
              <h2 className='text-center capitalize'>{title}</h2>
            </div>

            <button
              className='absolute top-2 right-4 text-gray-500 hover:text-gray-700 focus:outline-none'
              onClick={closeModal}
            >
              &times;
            </button>
          </div>
          <div className='mt-2 text-center'>{children}</div>
        </div>
      </div>
    )
  )
}

export default Modal
type Modal2Props = {
  open: boolean
  setOpen: (data: boolean) => void
  children: any
  title?: string
  buttons: Button2Props[]
}

export const Modal2 = (props: Modal2Props) =>
  props.open ?
    <div
      className='relative z-30'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='fixed inset-0 bg-gray-500/75 transition-opacity'
        aria-hidden='true'
      ></div>

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
        <div
          className='fixed inset-0 transition-opacity'
          onClick={() => props.setOpen(false)}
        />
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative w-full max-w-lg transform bg-white rounded-lg shadow-lg text-left transition-all sm:max-w-2xl sm:w-full '>
            <div className='bg-white p-4 rounded-t-lg'>
              <div className='sm:flex sm:items-start'>
                <div className='mt-3 w-full text-center sm:mt-0 sm:text-left'>
                  {props.title ?
                    <>
                      <div
                        className={`absolute top-0 left-0 w-[80%] bg-primary text-white text-center py-2 rounded-tr-lg`}
                      >
                        <h2 className='text-center capitalize'>
                          {props.title}
                        </h2>
                      </div>

                      <button
                        className='absolute top-2 right-4 text-gray-500 hover:text-gray-700 focus:outline-none'
                        onClick={() => props.setOpen(false)}
                      >
                        &times;
                      </button>
                    </>
                  : null}
                  <div className='mt-10'>{props.children}</div>
                </div>
              </div>
            </div>
            <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 rounded-b-lg'>
              {props.buttons?.map((btn: Button2Props, index: number) => {
                return <Button2 key={index} {...btn} />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  : null
