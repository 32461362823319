// api.js
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const create_subscription_api = async (data: SubscriptionModel) =>
  axios.post(`${BASE_URL}/subscription`, data) as any

export const update_subscription_api = async (data: SubscriptionModel) => {
  return await axios.put(`${BASE_URL}/subscription/${data.id}`, data)
}

export const delete_subscription_api = async (data: string | string[]) => {
  return await axios.delete(`${BASE_URL}/subscription/${data}`)
}

export const attach_payment_method_api = async () =>
  axios.get(
    `${BASE_URL}/business/attach_payment_method?return_url=${window.location.href}`
  ) as any

export const customer_portal_api = async () =>
  axios.get(
    `${BASE_URL}/business/customer_portal?return_url=${window.location.href}`
  ) as any

export type SubscriptionModel = {
  id?: string
  plan_id: string
  interval: 'month' | 'year'
  period_count: number
  auto_renew: boolean
}
