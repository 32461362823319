import { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { refresh_token_api } from '../auth'

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const token = localStorage.getItem('token')
      if (token) config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      response?.data?.message && toast.success(response?.data?.message)
      return response?.data?.data
    },
    async (error: AxiosError) => {
      const originalRequest: any = error.config
      const refresh_token = localStorage.getItem('refresh_token')
      if (error?.response?.status === 401) {
        if (
          error?.response?.data === 'jwt expired' &&
          !originalRequest._retry &&
          refresh_token
        ) {
          originalRequest._retry = true
          const response = await refresh_token_api({ refresh_token })
          localStorage.setItem('token', response.token)
          localStorage.setItem('refresh_token', response.refresh_token)
          return axios(originalRequest)
        } else if (error?.response?.status === 401) {
          localStorage.clear()
          window.location.href = `/auth?redirect=${
            window.location.pathname + window.location.search
          }`
        }
        // throw error
      } else {
        const data: any = error?.response?.data
        if (data?.message) toast.error(data.message)
        throw error
      }
    }
  )
}
