import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button2 } from '../../../component/Button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '../../../component/Card'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { attach_payment_method_api } from '../../../service/subscription'
import { useMemo, useState } from 'react'
import { Modal2 } from '../../../component/Modal'

export const Alerts = ({ alerts }: any) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(true)
  const alert = useMemo(() => alerts[0], [alerts])
  const navigate = useNavigate()

  const attachPaymentMethod = useMutation({
    mutationFn: attach_payment_method_api,
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
    onSuccess: (data) => {
      window.location.replace(data)
    }
  })
  const data: any = {
    payment_method: {
      title: 'Payment Method Required',
      description:
        'Please add a payment method to continue using our services.',
      button: 'Add Payment Method',
      action: attachPaymentMethod.mutate
    },
    subscription: {
      title: 'Subscription Required',
      description: 'Please subscribe to a plan to continue using our services.',
      button: 'Subscribe Now',
      action: () => {
        navigate('/settings?section=subscriptions')
        setOpen(false)
      }
    }
  }
  const buttons = [
    {
      title: data[alert]?.button, //'Add Payment Method',
      onClick: data[alert]?.action,
      loading: loading
    },
    {
      title: 'Close',
      onClick: () => setOpen(false),
      btnStyle: 'outline'
    }
  ]
  return (
    <>
      <Card className='bg-orange-50 border-orange-200 mb-3'>
        <CardHeader className='pb-2'>
          <div className='flex gap-4'>
            <div className='flex-shrink-0 pt-1'>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className='h-8 w-8 text-orange-500'
              />
            </div>
            <div>
              <CardTitle className='text-lg text-orange-800 mb-2'>
                Action Required
              </CardTitle>
              <CardDescription className='text-orange-700'>
                {data[alert]?.description}
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Button2
            title='Add Payment Method'
            className='sm:ml-0'
            loading={loading}
            onClick={attachPaymentMethod.mutate}
            // onClick={() => navigate('/settings?section=payment-methods')}
          />
        </CardContent>
      </Card>
      <Modal2 open={open} setOpen={setOpen} buttons={buttons}>
        <div className='max-w-md m-auto'>
          <div className='flex flex-col space-y-2 text-center sm:text-left items-center'>
            <div className='bg-primary bg-opacity-20 p-3 rounded-full mb-4 h-12'>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className='h-6 w-6 text-primary'
              />
            </div>
            <div className='text-lg font-semibold text-gray-800'>
              {data[alert]?.title}
            </div>
            <div className='text-sm text-muted-foreground text-center text-gray-600 mt-2'>
              {data[alert]?.description}
            </div>
          </div>
        </div>
      </Modal2>
    </>
  )
}
