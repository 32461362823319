export const APPSTORE_URL =
  'https://apps.apple.com/ca/app/velocity-manager/id6714478634'
export const PLAYSTORE_URL =
  'https://play.google.com/apps/testing/com.velocitymgr'
export const ADMIN = ['System Administrator', 'Super Admin']
export const SUPER_ADMIN = 'Super Admin'
export const SAMPLE_UPLOAD_FILE_LINKS: Record<string, string> = {
  locations:
    'http://res.cloudinary.com/tobilasinde/raw/upload/v1737502029/schedule/development/B0001/import/locations/mc8lffvjgmpxi122fgcd.csv',
  services:
    'http://res.cloudinary.com/tobilasinde/raw/upload/v1737506131/schedule/development/B0001/import/services/eudmhqbleczffxdcfhc6.csv'
}
