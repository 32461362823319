import axios from 'axios'
import { queryParam, Response } from './_model'

export const get_signed_url = async (
  folder?: string
): Promise<{
  signature: string
  timestamp: string
  cloudname: string
  apikey: string
  folder: string
}> => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/upload_sign_url${
      folder ? `?folder=${folder}` : ''
    }`
  )
}
export const delete_file_api = async (id: string) => {
  return await axios.delete(`${process.env.REACT_APP_BASE_URL}/file/?id=${id}`)
}

export const upload_file = async (
  data: FileProps,
  cloudname: string
): Promise<any> => {
  const new_axios = axios.create({
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return await new_axios.post(
    'https://api.cloudinary.com/v1_1/' + cloudname + '/auto/upload',
    data
  )
}
export const import_data = async (data: {
  media: FileModel
  model: string
}): Promise<any> => axios.post(`${process.env.REACT_APP_BASE_URL}/import`, data)

export const notification_api = async (
  data: queryParam
): Promise<Response<NotificationModel[]>> =>
  axios.get(
    `${process.env.REACT_APP_BASE_URL}/notification?${data.queryKey[1] || ''}`
  )
export const update_notification_api = async (data: {
  id: string
  read_status: boolean
}) =>
  axios.put(`${process.env.REACT_APP_BASE_URL}/notification/${data.id}`, data)

export const get_request_api = async (data: any) =>
  (await axios.get(
    `${process.env.REACT_APP_BASE_URL}/${data.queryKey[0]}?${data.queryKey[1] || ''}`
  )) as any

type FileProps = {
  file: File
  api_key: string
  timestamp: string
  signature: string
  folder: string
}

export type FileModel = {
  id: string
  format?: string
  provider?: string
  file_name?: string
}
type NotificationModel = {
  id: string
  title: string
  model: string
  operation: string
  status: boolean
  extra: any
  created_at: string
  updated_by: string
  business_id: string
}
