import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button2 } from '../../../component/Button'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { update_setting_api } from '../../../service/settings'
import { useEffect, useState } from 'react'
import {
  faCheckCircle,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'

export const GuidedSetup = ({ settings, refetch }: any) => {
  const navigate = useNavigate()

  const updateSettingsMutation = useMutation({
    mutationFn: update_setting_api,
    onSuccess: () => {
      refetch()
    }
  })
  const guided_setup = [
    {
      name: 'settings',
      title: "Review your company's profile & settings",
      link: '/settings'
    },
    {
      name: 'roles',
      title:
        "Manage your company's organizational structure (Roles & Permissions)",
      link: '/users/roles'
    },
    {
      name: 'staffs',
      title: 'Invite your staffs to join the company',
      link: '/users'
    },
    {
      name: 'clients',
      title: 'Manage your Clients Data',
      link: '/locations'
    },
    {
      name: 'services',
      title: 'Add the services you render',
      link: '/services'
    }
  ]
  const [completed_guided_setup, setCompletedGuidedSetup] = useState(['all'])
  useEffect(() => {
    if (settings?.hasOwnProperty('completed_guided_setup'))
      setCompletedGuidedSetup(
        settings?.completed_guided_setup?.split(',') || []
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.completed_guided_setup])
  const updateGuidedSetup = (data: string) => {
    let completed = new Set(completed_guided_setup)
    completed.add(data)
    if (completed.size === guided_setup.length) completed.add('all')
    updateSettingsMutation.mutate({
      completed_guided_setup: Array.from(completed).join(',')
    })
    navigate(guided_setup.find((g) => g.name === data)?.link || '')
  }

  return !completed_guided_setup?.includes('all') ?
      <div className='bg-white rounded p-2 flex flex-col gap-2 h-fit mb-2'>
        <div className=' sm:flex p-1 justify-between'>
          <p className='text-lg font-medium text-left'>Guided Setup</p>
          <div className='flex flex-col sm:flex-row sm:items-center gap-2'>
            <Button2
              title='Remind Me Later'
              btnStyle='outline'
              onClick={() =>
                setCompletedGuidedSetup([...completed_guided_setup, 'all'])
              }
            />
            <Button2
              title='Mark As Completed'
              onClick={() => updateGuidedSetup('all')}
            />
          </div>
        </div>
        <hr />
        {guided_setup.map((item, index) => (
          <button
            key={index}
            onClick={() => updateGuidedSetup(item.name)}
            className={`flex w-full justify-between p-3  transition-all duration-300
                    ${completed_guided_setup.includes(item.name) && 'text-primary bg-primary bg-opacity-10'}
                    rounded items-center`}
          >
            <p>{item.title}</p>
            <div>
              {completed_guided_setup.includes(item.name) && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className='text-green-500 mr-2'
                />
              )}
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </button>
        ))}
      </div>
    : null
}
